<template>
  <div>
    <Navbar></Navbar>
    <div class="wrapper">
      <!-- section prices -->
      <section
        id="login"
        class="section jpadding jpadding-20 section-unfixed"
        style="background: #1193f5"
      >
        <!-- 
            <div class="illustration">
                <img src="/img/vectors/talkii-illustration2.svg" alt="">
            </div> -->

        <div class="jcard jcard-main jcard-register jcard-nohover">
          <div class="brand mb-3">
            <img
              src="@/assets/logos/talkii-logo-gradient.png"
              alt="Talkii Logo"
            />
          </div>
          <div class="sub-title text-center">
            <h2>Signup</h2>
          </div>

          <div class="login">
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label for="email">
                    <i class="fa fa-user"></i> First Name
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="userData.firstName"
                  />
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <label for="email"
                    ><i class="fa fa-user"></i> Last Name</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="userData.lastName"
                  />
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="email"
                ><i class="fa fa-map-marker"></i> Address</label
              >
              <input
                type="text"
                class="form-control"
                v-model="userData.address"
              />
            </div>

            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label for="email"
                    ><i class="fa fa-map-marker"></i> Zip</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="userData.zip"
                  />
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <div class="form-group">
                    <label for="email"
                      ><i class="fa fa-phone-alt"></i> Phone</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      v-model="userData.phone"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="email"
                ><i class="fa fa-globe-europe"></i> Country</label
              >
              <b-form-select
                v-model="userData.country"
                :options="countriesList"
              ></b-form-select>
            </div>

            <div class="form-group">
              <label for="email"><i class="fa fa-user"></i> Email</label>
              <input
                type="text"
                class="form-control"
                v-model="userData.email"
              />
            </div>

            <div class="form-group">
              <label for="password"><i class="fa fa-lock"></i> Password</label>
              <input
                type="password"
                class="form-control"
                v-model="userData.password"
              />
            </div>

            <div class="form-group">
              <label for="password"
                ><i class="fa fa-lock"></i> Repeat password</label
              >
              <input
                type="password"
                class="form-control"
                v-model="userData.passwordCheck"
              />
            </div>

            <div class="btn-container text-center mt-4 mb-4">
              <button class="jbtn jbtn-blue" value="Add" @click="registerUser">
                Singup!
              </button>
            </div>

            <div class="login-message">
              <p>{{ validationMsg }}</p>
              <p>{{ pwNotOkMsg }}</p>
            </div>

            <div class="bottom-links text-center">
              <router-link to="/login"
                ><p><a>Already have an account?</a></p></router-link
              >
            </div>
          </div>
        </div>
      </section>
    </div>
    <section class="separator separator-lux jpadding">
      <img src="@/assets/logos/made-in-lux.svg" alt="Made in Luxembourg" />
    </section>
  </div>
</template>

<script>
import firebase from "firebase/app";
import db from "../firebase/firebaseInit";
import Navbar from "@/components/Navbar";
import C from "@/constants";

export default {
  name: "Register",
  components: {
    Navbar: Navbar,
  },
  data() {
    return {
      title: "Create account",

      userData: {
        firstName: "",
        lastName: "",
        address: "",
        zip: "",
        country: "Luxembourg",
        phone: "",
        email: "",
        password: "",
        passwordCheck: "",
      },
      userDoc: {
        ready: false,
        admin: false,
        premium: false,

        accType: 2, // platform_account
        status: 5, // expired
        token: null, // ignore
        sku: null, // subscription type
        expiry: Math.floor(Date.now()), // in ms

        ic: "",
        name: "",
        email: "",
        addr: "",
        zip: "",
        country: "",
        phone: "",

        children: new Array(),
        chName: new Array(),
        chColor: new Array(),

        chLang: new Array(),
        chLayout: new Array(),
        chColumn: new Array(),
        chShowTitle: new Array(),
        chAiVoice: new Array(),
        chTlWeekMode: new Array(),
        chShowTime: new Array(),
        cTlCount: new Array(),
        eTlLastAcc: new Array(),

        active: -1,

        tlColors: [
          "#d3d3d3",
          "#d3d3d3",
          "#d3d3d3",
          "#d3d3d3",
          "#d3d3d3",
          "#d3d3d3",
          "#d3d3d3",
        ],

        eTlMode: false,
        upV: Math.floor(Date.now()),
      },
      countriesList: C.COUNTRIES,
      validEmail: true,
      validFirstName: true,
      validLastName: true,
      validPassword: true,

      validationMsg: "",
      pwNotOkMsg: "",
    };
  },
  methods: {
    registerUser() {
      // TODO: MORE INPUT VALIDATION

      if (this.validate()) {
        this.validationMsg = "SUCCESS";
        firebase
          .auth()
          .createUserWithEmailAndPassword(
            //create auth user
            this.userData.email,
            this.userData.password
          )
          .then(() => {
            //update auth user
            firebase
              .auth()
              .currentUser.updateProfile({
                displayName:
                  this.userData.firstName + " " + this.userData.lastName,
              })
              .then(() => {
                //create user doc
                this.validationMsg =
                  "Welcome " + firebase.auth().currentUser.displayName;
                db.collection("users")
                  .doc(firebase.auth().currentUser.uid)
                  .set({
                    firstname: this.userData.firstName,
                    lastname: this.userData.lastName,
                    email: this.userData.email,
                  })
                  .then(() => {
                    this.pwNotOkMsg = "";
                    this.$router.replace("/");
                  })
                  .catch((err) => {
                    this.pwNotOkMsg = "COULD NOT CREATE DOCUMENT";
                    this.validationMsg = err.message;
                  });
              })
              .catch((err) => {
                this.pwNotOkMsg = "COULD NOT UPDATE PROFILE";
                this.validationMsg = err.message;
              });
          })
          .catch((err) => {
            this.pwNotOkMsg = "COULD NOT CREATE USER";
            this.validationMsg = err.message;
          });
        this.pwNotOkMsg = "registration in progress";
      } else {
        this.validationMsg = "Please complete the form";
      }
    },
    validate() {
      //TODO: use array so we see all the problems at once

      //Check first name
      if (this.userData.firstName.length < 2) {
        this.validFirstName = false;
        return false;
      }
      this.validFirstName = true;
      //Check last name
      if (this.userData.lastName.length < 2) {
        this.validLastName = false;
        return false;
      }
      this.validLastName = true;
      //Check email
      if (!this.checkEmail(this.userData.email)) {
        this.validEmail = false;
        return false;
      }
      this.validEmail = true;
      //Check password
      if (this.userData.password.length < 8) {
        this.validPassword = false;
        this.pwNotOkMsg = "Your password is too short (8 characters min.)";
        return false;
      }
      this.pwNotOkMsg = "";
      //Check confirm password
      if (this.userData.password != this.userData.passwordCheck) {
        this.validPassword = false;
        this.pwNotOkMsg = "Your passwords did not match";
        return false;
      }
      this.pwNotOkMsg = "";
      this.validPassword = true;
      return true;
    },
    checkEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  },
};
</script>

<style lang="scss" scoped></style>
